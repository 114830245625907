<template lang="pug">
v-chip(
  small
  :color="color.bg"
  :text-color="color.text"
)
  v-icon.ml-1.mr-2( small left ) {{ icon }}
  span.inline-block.mr-1 {{ formattedStatus | capitalize }}
</template>

<script>
import { ref, computed } from '@vue/composition-api';

export default {
  name: 'ExpenseChip',

  props: ['expense'],

  setup(props) {
    const expenseRef = ref(props.expense);
    const raw = computed(() => {
      const { status } = expenseRef.value;
      return status;
    })

    const formattedStatus = computed(() => {
      const { status } = expenseRef.value;

      switch (status) {
        case 'rejected':
          return 'Rejected';
        default:
          return status;
      }
    });

    const icon = computed(() => {
      const { status  } = expenseRef.value;

      switch (status) {
        case 'incomplete': return 'mdi-progress-alert';
        case 'submitted': return 'mdi-file-document-arrow-right-outline';
        case 'rejected': return 'mdi-undo-variant';
        case 'approved': return 'mdi-check';
        case 'confirmed': return 'mdi-check-all';
        default: return '';
      }
    });

    const color = computed(() => {
      const { status  } = expenseRef.value;

      switch (status) {
        case 'incomplete':
          return { bg: 'gray', text: 'gray' };
        case 'submitted':
          return { bg: 'primary', text: 'white' };
        case 'rejected':
          return { bg: 'red', text: 'white' };
        case 'approved':
          return { bg: 'green', text: 'white' };
        case 'confirmed':
          return { bg: 'blue darken-1', text: 'white' };
        default: return '';
      }
    })

    return {
      icon,
      color,
      formattedStatus,
      status: raw
    }
  }
}
</script>

<style>
</style>