<template lang="pug">
.expense-create.mt-6
  div( class="w-full px-8 xl:px-0 xl:w-8/12 2xl:w-1/2 mx-auto" )
    .d-flex.justify-between.align-center( v-if="!isPending" )
      v-breadcrumbs(
        class="ml-0 pl-0"
        :items="breadcrumbs"
      )
      ExpenseChip.mr-1( :expense="expense" )

    v-tabs.shadow.rounded-tr-lg.rounded-tl-lg.overflow-hidden(
      grow
      v-model="tab"
    )
      v-tab Expense
      v-tab( v-if="id !== 'new'" )
        | History {{ expenseHistories.length ? '(' + expenseHistories.length + ')' : '' }}

      v-tabs-items( v-model="tab" )
        v-tab-item.pa-8.border-t
          template( v-if="isPending")
            h5.mt-8 Identity
            v-divider
            div
              .rounded.h-16.bg-gray-300.animiate-pulse
            h5.mt-8 Invoice
            v-divider
            div.space-y-10
              .rounded.h-16.bg-gray-300.animiate-pulse
              .rounded.h-16.bg-gray-300.animiate-pulse
              .rounded.h-16.bg-gray-300.animiate-pulse
              .rounded.h-16.bg-gray-300.animiate-pulse
              .rounded.h-16.bg-gray-300.animiate-pulse
          template( v-if="!isPending" )
            v-chip.rounded.w-full.mb-8(
              color="red"
              outlined
              v-if="expense.rejectNote && expense.status === 'rejected'"
            )
              v-icon.ml-1( small color="red" left ) mdi-comment
              span.text-red-800 {{ expense.rejectNote }}

            FormWrapper(
              v-if="!isPending"
              @submit="saveExpense"
              :item="expense"
            )
              template( v-slot="{ save, clone, rules, valid }" )
                div( v-if="isOrganization" )
                  h5 Identity
                  v-divider.mb-5

                  .d-block.d-sm-flex.justify-between.align-middle.gap-8
                    .flex-1
                      OrgTypeSelect.mb-6(
                        v-if="isOrganization && isOrgAdmin"
                        v-model="clone.organizationTypeId"
                        single-select
                        @saved="selectOrgType($event, clone)"
                      )

                      v-select(
                        v-if="isOrganization && !isOrgAdmin"
                        class="min-w-0"
                        :items="orgTypes"
                        v-model="clone.organizationTypeId"
                        outlined
                        dense
                        label="Org Types"
                        item-value="id"
                        @change="selectOrgType($event, clone)"
                        item-text="name"
                        :isLoading="isOrgTypeFindPending"
                        no-data-text="No Org types found"
                        :rules="[rules.required]"
                      )

                    .flex-1
                      v-select(
                        v-if="advocacies.length"
                        :items="assignments"
                        v-model="clone.assignedTo"
                        outlined
                        dense
                        @change="assignedToSelect(clone)"
                        :disabled="!(!!clone.organizationTypeId) || clone.organizationTypeId.length === 0"
                        label="Assigned to"
                      )

                      v-select(
                        v-if="clone.assignedTo === 'Candidate'"
                        class="min-w-0"
                        :items="candidates"
                        v-model="clone.candidateId"
                        outlined
                        dense
                        :disabled="!(!!clone.organizationTypeId) || clone.organizationTypeId.length === 0"
                        label="Candidates"
                        item-value="candidate.id"
                        item-text="candidate.fullName"
                        :isLoading="isCandidateFindPending"
                        no-data-text="No Candidates found"
                        :rules="[rules.required]"
                      )

                      v-select(
                        v-if="advocacies.length && clone.assignedTo === 'Issue'"
                        class="min-w-0"
                        :items="advocacies"
                        v-model="clone.advocacyId"
                        outlined
                        dense
                        :disabled="!(!!clone.organizationTypeId) || clone.organizationTypeId.length === 0"
                        label="Issues"
                        item-value="advocacy.id"
                        item-text="advocacy.name"
                        :isLoading="isAdvocacyFindPending"
                        no-data-text="No Issues found"
                        :rules="[rules.required]"
                      )


                h5 Invoice
                v-divider.mb-5

                .d-block.d-sm-flex.justify-between.align-middle.gap-8
                  DatePickerDialog(
                    inputClass="w-full md:w-1/2 min-w-0"
                    label="Invoice Date"
                    v-model="clone.invoiceDate"
                  )

                  v-text-field(
                    label="Invoice Number"
                    class="w-full md:w-1/2 min-w-0"
                    v-model="clone.invoiceNumber"
                    outlined
                    dense
                    persistent-hint
                    :rules="[rules.required]"
                  )

                .d-block.d-sm-flex.justify-between.align-center.gap-8
                  div( class="w-full md:w-1/2" )
                    AmountFormItem(
                      v-model="clone.amount"
                      :rules="rules"
                    )
                  div( class="w-full md:w-1/2" )
                    v-checkbox(
                      label="Is a Direct Contribution"
                      v-model="clone.isDirectContribution"
                      hide-details
                    )

                v-textarea(
                  class="mt-6"
                  label="Notes"
                  v-model="clone.notes"
                  counter
                  :rules="[ rules.max(250) ]"
                  outlined
                  dense
                  rows="3"
                )

                template( v-if="!clone.isDirectContribution" )
                  h5.mt-5 Vendor
                  v-divider.mb-5

                  .d-block.d-sm-flex.justify-between.align-middle.gap-8
                    div( class="w-full md:w-1/2" v-if="!isVendorFindPending" )
                      v-select(
                        label="Select a Vendor"
                        v-model="clone.vendorId"
                        :items="vendors"
                        outlined
                        item-text="name"
                        item-value="id"
                        dense
                        no-data-text="No Vendors"
                        :isLoading="isVendorFindPending"
                        persistent-hint
                      )
                      .text-gray-600.text-sm.ml-2.mt-2.relative
                        a.mr-1.absolute.left-0(
                          style="top: -28px"
                          @click="isVendorCreateDialogOpen = true"
                        ) or
                          v-btn( color="primary" class="ml-1" depressed x-small ) Add a Vendor

                    v-select(
                      class="w-full md:w-1/2 min-w-0"
                      v-model="clone.type"
                      outlined
                      dense
                      label="Type"
                      :items="types"
                      :disabled="clone.isDirectContribution"
                    )

                  .d-block.d-sm-flex.justify-between.align-middle.gap-8.mt-4(
                    v-if="organizationId === 2"
                    class="w-1/2"
                  )
                    StateList(
                      v-model="expenseStateId"
                    )

                h5.mt-8 Reach
                v-divider.mb-5

                .d-block.d-sm-flex.justify-between.align-middle.gap-8
                  DatePickerDialog(
                    inputClass="w-full md:w-1/2 min-w-0"
                    label="Run Start Date"
                    v-model="clone.runDateStart"
                  )

                  DatePickerDialog(
                    inputClass="w-full md:w-1/2 min-w-0"
                    label="Run End Date"
                    v-model="clone.runDateEnd"
                  )

                .d-block.d-sm-flex.justify-between.align-middle.gap-8
                  v-text-field(
                    label="Households"
                    class="w-full md:w-1/3 min-w-0"
                    v-model="clone.numOfHouseholds"
                    outlined
                    hide-details
                    dense
                    :rules="[rules.isNumeric]"
                  )

                  v-text-field(
                    label="Impressions"
                    class="w-full md:w-1/3 min-w-0"
                    v-model="clone.numOfImpressions"
                    outlined
                    hide-details
                    dense
                    :rules="[rules.isNumeric]"
                  )

                  v-text-field(
                    label="Pieces"
                    class="w-full md:w-1/3 min-w-0"
                    v-model="clone.numOfPieces"
                    outlined
                    hide-details
                    dense
                    :rules="[rules.isNumeric]"
                  )

                .d-flex.justify-between.mt-8.align-baseline
                  .d-flex
                    h5.mr-2 Upload
                    v-tooltip(
                      bottom
                      max-width="250px"
                      color="primary"
                    )
                      template( v-slot:activator="{ on, attrs }" )
                        v-icon(
                          v-bind="attrs"
                          v-on="on" small
                        ) mdi-help-circle
                      .text-center To select multiple files, hold down ctrl and click on multiple files
                  .text-sm.text-gray-500( v-if="!clone.isDirectContribution" ) Upload both invoice and creative
                v-divider.mb-5

                div( v-if="!isPending" )
                  .text-xs( v-if="clone.attachments && clone.attachments.length" ).text-gray-400
                    | {{ clone.attachments.length }} files
                  .d-flex.items-center.align-middle.my-1(
                    v-for="attachment in clone.attachments"
                    :key="attachment.id"
                  )
                    .flex-grow-1.text-sm
                      span( style="word-break: break-all;" ) {{ attachment.name }}
                      span.inline-block.ml-1.text-xs.text-gray-400 ({{ attachment.size }})
                    .flex-shrink-1
                      .d-flex
                        ConfirmDelete(
                          @close="deleteAttachment"
                          :id="attachment.id"
                          :text="`Are you sure you want to delete ${ attachment.name }?`"
                        )
                        v-btn.ml-1( color="white" x-small fab depressed )
                          a( :href="attachment.url" :download="attachment.name" )
                            v-icon mdi-download

                  .mb-6( v-if="clone.attachments && clone.attachments.length" )

                v-file-input(
                  counter,
                  label="File input",
                  placeholder="Select your files",
                  prepend-icon="mdi-paperclip",
                  v-model="clone.$uploads"
                  multiple
                  outlined
                  :show-size="1000"
                )
                  template( v-slot:selection="{ text }" )
                    v-chip(
                      color="primary",
                      dark,
                      small
                    ) {{ text }}


                ExpenseActions.mt-16(
                  context="createEdit"
                  :expense="expense"
                  :valid="valid",
                  :clone="clone"
                  @submit-action="saveExpense({ save, clone })"
                )

        //- TAB 2 ------------------------------------------------------------------------------
        v-tab-item.pa-8.border-t( v-if="!isExpenseHistoriesPending" )
          ExpenseHistoryTimeline( :expenseHistories="expenseHistories" )

    VendorCreateDialog(
      @close="findVendors(); isVendorCreateDialogOpen = false"
      v-if="isVendorCreateDialogOpen"
      title="Add Vendor"
      :isOpen="isVendorCreateDialogOpen"
    )

</template>

<script>
import { ref, computed, watchEffect } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import useApiGet from '@/api/useApiGet'
import { pick } from 'lodash'
import { diff } from 'deep-object-diff'
import useApiFind from '@/api/useApiFind'
import FormWrapper from '@/components/FormWrapper.vue'
import { isOrganization } from '@/helpers/identityHelper'
import DatePickerDialog from '@/components/DatePicker.Dialog'
import VendorCreateDialog from '@/views/vendors/Vendor.Create.Dialog'
import types from './Expense.data';
import AmountFormItem from '@/components/Amount.FormItem'
import pdf from 'vue-pdf';
import ConfirmDelete from '@/components/Confirm.Delete'
import ExpenseChip from './Expense.Chip.vue'
import ExpenseActions from './Expense.Actions.vue'
import ExpenseHistoryTimeline from './ExpenseHistory.Timeline.vue'
import permissions from '@/helpers/uiContext';
import OrgTypeSelect from '@/views/organization-types/OrgType.Select.Dialog'
import StateList from '@/components/StateList'

export default {
  name: 'ExpenseCreateEdit',

  components: {
    ConfirmDelete,
    VendorCreateDialog,
    DatePickerDialog,
    FormWrapper,
    AmountFormItem,
    pdf,
    ExpenseChip,
    ExpenseActions,
    ExpenseHistoryTimeline,
    OrgTypeSelect,
    StateList
  },

  setup(props, { root: { $FeathersVuex, $route, $router, $store, $snackSuccess, $snackError } }) {
    const isVendorCreateDialogOpen = ref(false)
    const {
      ExpenseHistories,
      Expense,
      OrganizationType,
      AdvocacyOrganizationType,
      CandidateOrganizationType,
      Vendor,
      Attachment
    } = $FeathersVuex.api

    const { id } = $route.params;
    const { isOrgAdmin } = permissions()
    const expenseStateId = ref(0)
    const assignments = ref(['Candidate', 'Issue'])

    const { organizationId, group } = $store.getters['auth/user'];
    const eagers = group === 'organization'
      ? '[orgType, vendor(orderedByName), candidate, attachments, history(latest)]'
      : '[vendor(orderedByName), candidate, attachments, history(latest)]'
    const { item: expense, isPending, get } = useApiGet({
      model: Expense,
      id,
      params: {
        query: { $eager: eagers }
      },
      // only applied on id === 'new'
      defaultValues: {
        status: 'incomplete',
        actions: {
          edit: true,
          submit: true
        }
      }
    })

    const saveExpense = async ({ save, clone }) => {
      isPending.value = true;

      const fromStore = await Expense.getFromStore(clone.id)

      try {
        if (clone.assignedTo === 'Candidate') {
          clone.advocacyId = null;
        } else {
          clone.candidateId = null;
        }
        clone.assignedTo = clone.assignedTo === 'Candidate'
          ? clone.assignedTo.toLowerCase()
          : 'advocacy'

        /*  always insure that if there is a check here,
            force the direct contribution to be the vendor type */
        if (clone.isDirectContribution) {
          clone.type = 'Direct Contribution'
        } else {
          clone.isDirectContribution = false;
        }

        // there is only one candidate within a committee account
        if (!isOrganization) {
          const [{ id: candidateId }] = candidates.value;
          clone.candidateId = candidateId;
        }

        if (Array.isArray(clone.organizationTypeId)) {
          clone.organizationTypeId = clone.organizationTypeId[0]
          delete clone.organizationTypeIds
        }

        if (clone.$uploads && clone.$uploads.length) {
          const uploads = [];

          for (let doc of clone.$uploads) {
            uploads.push({
              name: doc.name,
              url: '',
              size: doc.size,
              doc
            })
          }

          clone.$uploads = uploads;
        }

        if (id === 'new')
          await save()
        else {
          const requiredData = pick(
            clone,
            ['id', 'attachments', 'status', 'invoiceNumber', 'vendorId', 'isDirectContribution']
          )
          const changes = diff(fromStore, clone)
          const dataToSend = Object.assign({}, requiredData, changes)

          await save({ data: dataToSend })
        }

        $router.push('/expenses');
        $snackSuccess(`Expense ${ id === 'new' ? 'Added' : 'Saved' }`);

        // clear this from store/memory as well as $uploads > attachments
        clone.$uploads = null;
      } catch (e) {
        console.log(e)
        $snackError(e.message)
      }
    }

    let orgTypes = ref([])
    let isOrgTypeFindPending = ref(false)

    if (isOrganization) {
      const orgTypeData = useFind({
        model: OrganizationType,
        params: {
          query: {
            $sort: { name: 1 }
          },
          $limit: 99
        }
      })

      orgTypes = orgTypeData.items;
      isOrgTypeFindPending = orgTypeData.isPending;
    }

    const {
      items: expenseHistories,
      isPending: isExpenseHistoriesPending
    } = useFind({
      model: ExpenseHistories,
      params: {
        query: {
          $eager: '[user]',
          expenseId: parseInt(id),
          $sort: { createdAt: -1 }
        }
      },
      queryWhen: computed(() => id !== 'new')
    })

    const selectOrgType = async (ids, clone) => {
      ids = Array.isArray(ids) ? ids : [ids]

      await fc({
        query: {
          organizationTypeId: { $in: ids },
          $sort: {
            'candidate.firstName': 1
          },
          $limit: 99,
          $joinEager: '[candidate]'
        }
      })

      fa({
        query: {
          organizationTypeId: { $in: ids },
          $sort: {
            'advocacy.name': 1
          },
          $limit: 99,
          $joinEager: '[advocacy]'
        }
      })

      if (clone) {
        clone.assignedTo = 'Candidate';
        clone.advocacyId = null
      }
    };

    watchEffect(() => {
      if (!isPending.value && id !== 'new') {
        selectOrgType([expense.value.organizationTypeId])
      }
    })

    const { items: candidates, isPending: isCandidateFindPending, findItems: fc } = useApiFind({
      model: CandidateOrganizationType,
      params: { query: {} },
      immediate: false
    })

    const { items: advocacies, isPending: isAdvocacyFindPending, findItems: fa } = useApiFind({
      model: AdvocacyOrganizationType,
      params: { query: {} },
      immediate: false
    })

    const deleteAttachment = async (attachmentId) => {
      if (attachmentId) {
        try {
          const attachment = new Attachment({ id: attachmentId })
          await attachment.remove();
          get(id, {
            query: { $eager: '[attachments]' }
          })
        } catch (e) {
          console.error(e);
        }
      }
    }

    const { items: vendors, isPending: isVendorFindPending, findItems: findVendors } = useApiFind({
      model: Vendor,
      params: {
        query: {
          $sort: { name: 1 },
          $limit: 99
        }
      }
    })

    const tab = ref('expense')
    const breadcrumbs = computed(() => {
      const hasValue = expense.value.invoiceNumber;

      return [{
        text: 'Expenses',
        link: true,
        disabled: false,
        exact: true,
        to: '/expenses'
      },
      {
        text: hasValue || 'Incomplete Invoice',
        disabled: true
      }]
    })

    const assignedToSelect = (clone) => {
      console.log(clone)
      if (clone.assignedTo === 'Issue') {
        clone.isDirectContribution = false;
      }
    }

    return {
      expense,
      types,
      saveExpense,
      findVendors,
      isPending,
      tab,
      breadcrumbs,
      assignments,
      isOrgAdmin,
      selectOrgType,
      assignedToSelect,

      expenseHistories,
      isExpenseHistoriesPending,

      orgTypes,
      isOrgTypeFindPending,
      candidates,
      isCandidateFindPending,
      advocacies,
      isAdvocacyFindPending,
      vendors,
      isVendorFindPending,

      isOrganization,
      isVendorCreateDialogOpen,
      id,
      organizationId,

      expenseStateId,

      deleteAttachment
    }
  }
}
</script>

<style scoped>
::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px
}
</style>