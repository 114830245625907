<template lang="pug">
.expense-reject-dialog
  CreateEditDialog(
    :item="expense"
    title="Reject Expense"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveExpense"
    saveAction="Reject"
  )
    template(
      v-slot:form="{ clone, rules }"
    )
      v-textarea(
        outlined
        autofocus
        counter
        auto-grow
        row-height="15"
        v-model="clone.note"
        hint="150 character max"
        persistent-hint
        label="Note"
        :rules="[rules.required, rules.max(150)]"
      )
</template>

<script>
import CreateEditDialog from '@/components/Dialogs/CreateEdit.Dialog'
import { toRefs } from '@vue/composition-api'

export default {
  name: 'ExpenseRejectDialog',

  props: {
    id: Number,
    isOpen: Boolean
  },

  components: {
    CreateEditDialog
  },

  setup(props, { emit, root: { $FeathersVuex, $snackSuccess, $snackError, $store } }) {
    const { Expense } = $FeathersVuex.api
    const { id: expenseId } = toRefs(props);
    const expense = new Expense({
      id: expenseId.value,
      note: ''
    })

    const saveExpense = async ({ save, clone }) => {
      try {
        const { note, user } = clone;
        const data = {
          status: 'rejected',
          note,
          user
        }
        await save({ data })
        $snackSuccess('Successfully saved')
        emit('close')
      } catch (e) {
        $snackError('Error saving the Expense.' + e.message);
      }
    }

    return {
      expense,
      saveExpense
    }
  }
}
</script>

<style>
</style>