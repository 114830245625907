<template lang="pug">
.vendor-create-dialog
  CreateEditDialog(
    title="Add Vendor"
    :item="vendor"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveVendor"
  )
    template( 
      v-slot:form="{ clone, rules }"
    )
      v-text-field(
        outlined
        dense
        label="Vendor Name"
        v-model="clone.name"
        :rules="[rules.required]"
      )

</template>

<script>
import { useFind } from 'feathers-vuex'
import CreateEditDialog from '@/components/Dialogs/CreateEdit.Dialog'

export default {
  name: 'VendorCreateDialog',

  props: {
    title: {
      type: String,
      default: 'Add Vendor'
    },
    isOpen: Boolean
  },

  components: {
    CreateEditDialog
  },

  setup(props, { root: { $FeathersVuex, $snackError, $snackSuccess }, emit }) {
    const { Vendor } = $FeathersVuex.api
    const vendor = new Vendor()

    const { items: vendors, isPending } = useFind({
      model: Vendor,
      params: {
        query: {}
      }
    })

    const saveVendor = async ({ save, reset }) => {
      try {
        await save();
        $snackSuccess('Vendor added successfully')
        emit('close')
      } catch (e) {
        $snackError('Vendor Error: ', e)
      } finally {
        reset()
      }
    }

    return {
      vendor,
      vendors,
      isPending,

      saveVendor
    }
  },
}
</script>

<style>

</style>