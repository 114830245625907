<template lang="pug">
.confirm-delete-dialog
  v-dialog(
    max-width="600"
    persistent
    v-model="isOpen"
  )
    template( v-slot:activator="{ on, attrs }")
      v-btn( 
        color="white" 
        x-small 
        fab 
        depressed 
        v-on="on"
        v-bind="attrs"
      )
        v-icon mdi-close

    v-card
      v-card-text.border-t-4.border-red-500.pa-8.py-12
        .d-flex.align-center
          v-icon( color="red" large ).pr-4 mdi-alert-circle-outline
          .text-base.text-gray-600 {{ text }}

      v-card-actions( right ).bg-gray-100
        v-btn(
          depressed
          @click="isOpen = false"
        ) Cancel 
        v-btn(
          depressed
          @click="isOpen = false; $emit('close', id)"
          color="secondary"
        ) Delete
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  name: 'ConfirmDelete',

  props: {
    id: Number,
    text: String
  },

  setup() {
    const isOpen = ref(false);

    return {
      isOpen
    }
  }
}
</script>

<style>

</style>