<template lang="pug">
.expense-actions
  .d-flex.justify-end.gap-4
    template( v-if="context === 'createEdit'" )
      v-btn(
        v-if="expense.actions.edit"
        :disabled="!clone.organizationTypeId || !(clone.candidateId || clone.advocacyId)"
        depressed
        type="submit"
        color="gray"
        outlined
      ) Save

      v-btn(
        v-if="expense.actions.submit"
        :disabled="!validToSubmit(valid, clone)"
        depressed
        @click="modifySave(clone, 'submitted')"
        color="primary"
      )
        v-icon.mr-1( small ) mdi-file-document-arrow-right-outline
        span Submit

    template( v-if="context === 'view'" )
      v-btn(
        v-if="expense.actions.edit"
        depressed
        @click="goToCreateEdit"
        type="submit"
        color="primary"
      )
        span Edit

    v-btn(
      v-if="expense.actions.reject"
      depressed
      @click="openExpenseReject = true"
      type="submit"
      color="red"
    )
      span.text-red-100 Reject

    v-btn(
      v-if="expense.actions.approve"
      depressed
      @click="approveOrComplete(expense.id, 'approved')"
      type="submit"
      color="green"
    )
      v-icon.mr-2( small color="white" ) mdi-check
      span.text-green-50 Approve

    v-btn(
      v-if="expense.actions.confirm"
      depressed
      @click="approveOrComplete(expense.id, 'confirmed')"
      type="submit"
      color="blue"
    )
      v-icon.mr-2( small color="white" ) mdi-check-all
      span.text-green-100 Confirm

  ExpenseRejectDialog(
    :id="expense.id"
    :isOpen="openExpenseReject"
    @close="runEmit(); openExpenseReject = false;"
    v-if="openExpenseReject"
  )

</template>

<script>
import { ref } from '@vue/composition-api'
import ExpenseRejectDialog from './Expense.Reject.Dialog.vue'

export default {
  name: 'ExpenseActions',

  props: ['context', 'expense', 'valid', 'clone'],

  components: {
    ExpenseRejectDialog
  },

  setup(props, { emit, root: { $store, $snackSuccess, $snackError, $router } }) {
    const openExpenseReject = ref(false)

    const validToSubmit = (valid, clone) => {
      const { vendorId, invoiceNumber } = clone;
      const hasAnAttachment = clone.attachments?.length || clone.$uploads?.length;
      const validVendor = clone.isDirectContribution || vendorId;
      return valid && (validVendor && invoiceNumber) && hasAnAttachment;
    }

    const modifySave = (clone, status) => {
      clone.status = status;
      emit('submit-action')
    }

    const approveOrComplete = async (id, status) => {
      try {
        await $store.dispatch('expenses/patch', [id, { status, note: null }]);
        $snackSuccess('Successfully updated');
        emit('submit-action')
      } catch({ name, message }) {
        if (name === 'GeneralError')
          $snackError(message)
      }
    }

    const goToCreateEdit = () => {
      $router.push(`/expenses/createEdit/${ props.expense.id }`)
    }

    return {
      validToSubmit,
      modifySave,
      approveOrComplete,
      goToCreateEdit,
      runEmit: () => emit('submit-action'),

      openExpenseReject
    }
  }
}
</script>

<style>
</style>