export default [
  { text: 'Administrative', value: "Administrative" },
  { text: 'Canvassing', value: "Canvassing" },
  { text: 'Compliance', value: "Compliance" },
  { text: 'Data', value: "Data" },
  { text: 'Digital', value: "Digital" },
  { text: 'Direct Mail', value: "Direct Mail" },
  { text: 'In-Kind', value: "In-Kind" },
  { text: 'MMS', value: "MMS" },
  { text: 'Oppo Research', value: "Oppo Research" },
  { text: 'Other', value: "Other" },
  { text: 'Personnel', value: "Personnel" },
  { text: 'Phone', value: "Phone" },
  { text: 'Polling', value: "Polling" },
  { text: 'Radio', value: "Radio" },
  { text: 'Signage', value: "Signage" },
  { text: 'SMS', value: "SMS" },
  { text: 'Sponsorships', value: "Sponsorships" },
  { text: 'TV', value: "TV" },
]