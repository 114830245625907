import {
  isRef,
  reactive,
  toRefs,
  watch,
  computed
} from '@vue/composition-api'

export default function get(options) {
  const defaults = {
    model: null,
    id: null,
    params: null,
    defaultValues: null
  }

  const { model, id, params, defaultValues } = Object.assign(
    {},
    defaults,
    options
  )

  if (!model) {
    throw new Error(
      `No model provided for useApiGet(). Did you define and register it with FeathersVuex?`
    )
  }

  function getId() {
    return isRef(id) ? id.value : id || null
  }

  function getParams() {
    return isRef(params) ? params.value : params
  }

  const computes = {
    item: computed(() => {
      const getterId = isRef(id) ? id.value : id

      if (getterId === 'new') {
        return new model(defaultValues || {})
      } else {
        return model.getFromStore(getterId) || null
      }
    })
  }

  const state = reactive({
    isPending: false,
    error: null
  });

  function get(id, params) {
    const idToUse = isRef(id) ? id.value : id
    const paramsToUse = isRef(params) ? params.value : params

    state.isPending = true;

    if (idToUse !== 'new') {
      const promise =
        paramsToUse != null
          ? model.get(idToUse, paramsToUse)
          : model.get(idToUse)

      return promise
        .then(response => {
          state.isPending = false
          state.item = new model(response);
          return response
        })
        .catch(error => {
          state.isPending = false
          state.error = error
          return error
        })
    } else {
      return new Promise(res => {
        state.isPending = false
        res()
      })
    }
  }

  watch(
    [() => getId(), () => getParams()],
    () => { get(id, params) },
    { immediate: true }
  )

  return {
    ...toRefs(state),
    ...computes,
    get
  }
}